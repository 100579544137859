/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {





  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        // add custom media query sizes for Foundation Interchange
        Foundation.Interchange.SPECIAL_QUERIES.smallretina = 'only screen and (min-width: 1px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min-resolution: 192dpi), only screen and (min-width: 1px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.mediumretina = 'only screen and (min-width: 641px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 641px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 641px) and (min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min-resolution: 192dpi), only screen and (min-width: 641px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.largeretina = 'only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1025px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min-resolution: 192dpi), only screen and (min-width: 1025px) and (min-resolution: 2dppx)';
        Foundation.Interchange.SPECIAL_QUERIES.xlargeretina = 'only screen and (min-width: 1921px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1921px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min-resolution: 192dpi), only screen and (min-width: 1921px) and (min-resolution: 2dppx)';

        // init Foundation JavaScript
        $(document).foundation();

        // add plz pattern for Foundation Abide
        Foundation.Abide.defaults.patterns.plz = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;

        // set default Sticky size for Foundation Sticky
        Foundation.Sticky.defaults.stickyOn = 'large';

        // Mobile Menu
        Foundation.DropdownMenu.defaults.closeOnClick = true;

        // change body class on scroll
        $(window).scroll(function() {
          if ($(this).scrollTop() > 50) {
            $('body').addClass('solid-header');
          } else {
            $('body').removeClass('solid-header');
          }
        });

        // add no-touch class for desktop browsers
        var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
        if (!touchsupport) {
            document.documentElement.className += " no-touch ";
        }


          $('.slider-normal').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            pauseOnHover: false,
            fade: true,
            speed: 2000,
            cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
            autoplaySpeed: 2000,
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });


        $('.slider-row').slick({
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          dots: true,
          autoplay: false,
          pauseOnHover: false,
          fade: false,
          customPaging : function(slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<span class="normal">'+(i+1)+'</span>';
          },
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

        var numSlides = $('.slick-dots li').length;
        $( ".slick-dots li:last-child" ).after( '<span class="total"> / '+ numSlides +'</span>' );
        $('.slick-prev').appendTo(".slick-dots");
        $('.slick-next').appendTo(".slick-dots");


          $('.slider-gif').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            fade: true,
            speed: 0,
            pauseOnHover: false,
            autoplaySpeed: 500
          });


        $('.slider-autoplay').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          autoplay: true,
          pauseOnHover: false,
          fade: true,
          autoplaySpeed: 1000
        });


        $(".wpcf7-submit").click(function() {
          $('html,body').animate({
                scrollTop: $(".wpcf7").offset().top - 90},
              'fast');
        });

       // document.addEventListener( 'wpcf7mailsent', function( event ) {
        //   $(".section-inner").find('h1').hide();
        //  $(".section-inner").find('p').hide();
       //   $(".section-inner").find(".shadow").css("border", "none !important");
      //    $(".section-inner").find(".shadow").css("box-shadow", "none");
      //  }, false );


        $(window).scroll(function(){
          if ($(window).scrollTop() >= 100) {
            $('.fixed-header').addClass('fixed-visible');
          }
          else {
            $('.fixed-header').removeClass('fixed-visible');
          }
        });

        if ($(window).scrollTop() >= 100) {
          $('.fixed-header').addClass('fixed-visible');
        }
        else {
          $('.fixed-header').removeClass('fixed-visible');
        }



        $(".trigger-mobile-menu").click(function(){
          $(".mobile-menu").addClass("show-mobile-menu");
          $(".body-overlay").show("fast");
          $("body").css({
            'right':'125px',
            'transition' : 'all 0.2s'
          });
        });

        $(".close-mobile-menu, .body-overlay").click(function(){
          $(".mobile-menu").removeClass("show-mobile-menu");
          $(".body-overlay").hide("fast");
          $("body").css({
            'right':'0',
            'transition' : 'all 0.2s'
          });
        });

       /*   $('.wpcf7-date').datepicker();

          $('.wpcf7-date').focus(function(){
            $('.wpcf7-date').datepicker('show');
          });

          $('.wpcf7-date').click(function(){
            $('.wpcf7-date').datepicker('show');
          });
//$('#ui-datepicker-div').show();
          $('.wpcf7-date').datepicker('show');



        $('.wpcf7-date').focus(function(){
          $('.wpcf7-date').datepicker('show');
        });

*/





        // cookie disclainer
        var cookieBox = document.getElementById('js-cookie-box');
        var cookieButton = document.getElementById('js-cookie-button');

        if (!Cookies.get('cookie-box')) {

          cookieBox.classList.remove('cookie-box--hide');

          $(".close-cookie-button").on("click", function(){
            Cookies.set('cookie-box', true, { expires: 7 });
            cookieBox.classList.add('cookie-box--hide');
          });
        }


        // Form validation
        $(".standard-form").bind("submit",function(e) {
          e.preventDefault();
          console.log("submit intercepted");
          return false;
        });
        $(".standard-form").bind("invalid.zf.abide", function(ev,elem) {
          console.log("Field "+elem.attr('name')+" is invalid");
          var errorCount = $(this).find('[data-invalid]').length;
          if (errorCount===0) {
            $("#error-message").hide();
          }
        });
        $(".standard-form").bind("valid.zf.abide", function(ev,elem) {
          console.log("Field "+elem.attr('name')+" is valid");
          var errorCount = $(this).find('[data-invalid]').length;
          if (errorCount!==0) {
            $("#error-message").show();
            $("#error-count").html(errorCount);
          } else {
            $("#error-message").hide();
          }
        });
        $(".standard-form").bind("forminvalid.zf.abide", function(e,target) {
          console.log("form is invalid");
        });
        $(".standard-form").bind("formvalid.zf.abide", function(e,target) {
          console.log("form is valid");
          var formData = 'action=standardFormAction&post_id=' + my_ajax_object.post_id + '&' + $(this).serialize();

          $.ajax({
            url: my_ajax_object.ajax_url, // Let WordPress figure this url out...
            type: 'POST',
            dataType: 'JSON', // Set this so we don't need to decode the response...
            data: formData, // One-liner form data prep...
            beforeSend: function () {
              // You could do an animation here...
            },
            error: function () {
              console.log('error function fired');
            },
            success: function (data) {
              console.log(data.status);
              $("#ajaxoutput").html(data);
              if (data.status === 'success') {
                $(".standard-form").hide();
                $('#thank-you-message').fadeIn(300);
                $(window).trigger('resize');
              } else {
                console.log('success error');
              }
            },
            complete: function() {
              //console.log('Complete');
            }
          });
          return false;
        });


          $('.teaser-boxes').each(function () {
              if ( $(this).children().length == 1) {
                  $(this).css('justify-content','center');
              }
          });

          $('.teaser-boxes').each(function () {
              if ( $(this).children().length == 2) {
                  $(this).css('justify-content','center');
              }
          });

          $('.teaser-boxes').each(function () {
              if ( $(this).children().length == 5) {
                  $(this).css('justify-content','start');
              }
          });

          $('.teaser-boxes').each(function () {
              if ( $(this).children().length == 8) {
                  $(this).css('justify-content','start');
              }
          });

          $('.teaser-boxes').each(function () {
              if ( $(this).children().length == 11) {
                  $(this).css('justify-content','start');
              }
          });




        // Toggle menu
        $('[data-off-canvas]').on("opened.zf.offcanvas", function(e) {
          $('#toggle-menu').addClass('open');
          $('#main-header').addClass('toggle-menu-open');
          $('body').addClass('no-scroll toggle-menu-open');
          $('#toggle-menu-button').addClass('active');
        });
        $('[data-off-canvas]').on("closed.zf.offcanvas", function(e) {
          $('#toggle-menu').removeClass('open');
          $('#main-header').removeClass('toggle-menu-open');
          $('body').removeClass('no-scroll toggle-menu-open');
          $('#toggle-menu-button').removeClass('active');
        });

        $('#toggle-menu .menu-item-has-children > a').click(function(){
           var i = 0;
           var parent = $(this).parent();
           $('#toggle-menu ul > li').each(function(){
             if($('#toggle-menu ul > li').index(parent)!==i){
               $(this).removeClass('open');
               $(this).find('*').removeClass('open');
             }
             i++;
           });
           $(this).parent().toggleClass('open');
           $(this).toggleClass('open');
           $(this).siblings('.foldable-content').toggleClass('open');
           return false;
        });

        // Smooth scrolling
        if(window.location.hash) {
          $('html, body').animate({
              scrollTop: $(window.location.hash).offset().top - $('header').height() + 'px'
          }, 1000);
        }

        // Scrolldown Button scrolling
        $('.scrolldown-button').click(function(){
          var nextSection = $(this).closest('section').next();

          $('html, body').animate({
              scrollTop: nextSection.offset().top
          }, 1000);
        });

        function equalHeight(group) {
          var tallest = 0;
          group.css('height','auto'); //otherwise only works once!
          group.each(function() {
            var thisHeight = $(this).height();
            if(thisHeight > tallest) {
              tallest = thisHeight;
            }
          });
          group.height(tallest);
        }



        // Hoehen syncen
        if($(window).width() > 725){
         //  equalHeight($('.teaser-section .teaser-text'));
         // equalHeight($('.single-slider'));
          equalHeight($('.formular-height'));
          equalHeight($('.teaser-section .teaser-headline'));
          equalHeight($('.advantage-height'));
        }
        else {


          $('.teaser-section .teaser-text').css({'height': 'auto'});
        }

        function equalWidth(group) {
          var tallest = 0;
          group.css('width','auto'); //otherwise only works once!
          group.each(function() {
            var thisWidth = $(this).width();
            if(thisWidth > tallest) {
              tallest = thisWidth;
            }
          });
          group.width(tallest);
        }






        // Hoehen syncen
        if($(window).width() > 725){

          $('.text-section').each(function () {
            equalWidth($(".button", this));
          });

          $('.featurelist-section').each(function () {
            equalWidth($(".button", this));
          });

          equalWidth($('.header-section .button'));


        }

        else {


          $('.header-section .button').css({'width': 'auto'});
        }


          // langmenu
            $(".dropdown-lang").on("click", function(){
            $(".lang-menu, .dropdown-lang i, .dropdown-lang, .lang-menu-bg").toggleClass("active");
            $("body, html").toggleClass("noscroll");
          });

          $(".lang-menu-bg").on("click", function(){
              $(".lang-menu, .dropdown-lang i, .dropdown-lang, .lang-menu-bg").removeClass("active");
            $("body, html").removeClass("noscroll");
          });


// Arrow Down Scroll
        $(document).on('click', '.arrow-down', function(event){
          event.preventDefault();
          var viewportHeight = $(window).height();

          $('html, body').animate({
            scrollTop: viewportHeight + 10,
            complete: function () {
              //Hide your button here
            }
          }, 700);
        });

        // ie object-fit fallback
        function checkForIE() {
          var userAgent = window.navigator.userAgent;
          var ieReg = /msie|Trident.*rv[ :]*11\./gi;
          var ie = ieReg.test(userAgent);


          if(ie) {
            $(".image-wrap").each(function () {
              var $container = $(this);
              var imgUrl = $container.find("img").prop("src");
              var imgPos = $container.find("img").attr("data-object-position");
              if (imgUrl) {
                $container.css("backgroundImage", 'url(' + imgUrl + ')').addClass("ie-object-fit");
                if (imgPos) {
                  $container.css("background-position", imgPos);
                }
              }
            });
          }
        }
        checkForIE();



        // fire initial resize
        $(window).trigger('resize');

      },
      finalize: function() {
        $(window).trigger('resize');
        // JavaScript to be fired on all pages, after page specific JS is fired

      },
      resize: function() {




          function equalHeight(group) {
              var tallest = 0;
              group.css('height','auto'); //otherwise only works once!
              group.each(function() {
                  var thisHeight = $(this).height();
                  if(thisHeight > tallest) {
                      tallest = thisHeight;
                  }
              });
              group.height(tallest);
          }



          // Hoehen syncen!!
          if($(window).width() > 725){
            //  equalHeight($('.teaser-section .teaser-text'));
          //  equalHeight($('.single-slider .testimonal-quote'));
            equalHeight($('.formular-height'));
            equalHeight($('.teaser-section .teaser-headline'));
            equalHeight($('.advantage-height'));
          }
          else {


              $('.teaser-section .teaser-text').css({'height': 'auto'});
          }

        var headerHeight = $('#main-header').outerHeight(true);
        var footerHeight = $("#footer-wrap").outerHeight(true);
        var windowHeight = $(window).height();
        var combinedHeight = windowHeight - headerHeight - footerHeight;




        // IE
        // ie object-fit fallback
        function checkForIE() {
          var userAgent = window.navigator.userAgent;
          var ieReg = /msie|Trident.*rv[ :]*11\./gi;
          var ie = ieReg.test(userAgent);


          if(ie) {
            $(".header-section .single-slider").each(function () {
              var $container = $(this);
              var imgUrl = $container.find("img").prop("src");
              var imgPos = $container.find("img").attr("data-object-position");
              if (imgUrl) {
                $container.css("backgroundImage", 'url(' + imgUrl + ')').addClass("ie-object-fit");
                if (imgPos) {
                  $container.css("background-position", imgPos);
                }
              }
            });

            $(".blog-image-wrapper").each(function () {
              var $container = $(this);
              var imgUrl = $container.find("img").prop("src");
              var imgPos = $container.find("img").attr("data-object-position");
              if (imgUrl) {
                $container.css("backgroundImage", 'url(' + imgUrl + ')').addClass("ie-object-fit");
                if (imgPos) {
                  $container.css("background-position", imgPos);
                }
              }
            });
          }
        }

        checkForIE();


        if($('.events-section .events-counter.hide-event').length > 0 && ($('.events-section .events-counter.hide-event').length  === $('.events-section .events-counter').length)) {
          //return true
          $(".hidden-coming-soon").show();
        }







        // Sliders
        var resizeSliders = null;

        clearTimeout(resizeSliders);
        resizeSliders = setTimeout(
          function() {
            function renderCounter(slick,carouselId) {
              var slidesToShow;
              if(slick.activeBreakpoint!==null){
                slidesToShow = slick.breakpointSettings[slick.activeBreakpoint].slidesToShow;
              } else {
                slidesToShow = slick.options.slidesToShow;
              }

              if($('#'+carouselId).parents('.slider-wrapper').find('.slick-next').length>0){
                $('#'+carouselId).parents('.slider-wrapper').find('.counter').html( (slick.currentSlide+slidesToShow)/slidesToShow +' / ' + Math.ceil((slick.slideCount)/slidesToShow));
                $('#'+carouselId).parents('.slider-wrapper').find('.slider-control').show();
              } else {
                $('#'+carouselId).parents('.slider-wrapper').find('.counter').html('');
                $('#'+carouselId).parents('.slider-wrapper').find('.slider-control').hide();
              }
            }

            $('.common-slider').each(function (idx, item) {
              if ($(this).hasClass('slick-initialized')) {
                  $(this).slick('resize');
              } else {
                var carouselId = "carousel_" + idx;
                this.id = carouselId;
                $(this).attr('data-counter','carousel_slick-control_' + idx);
                $(this).next('.slider-control').attr('id','carousel_slick-control_' + idx);

                $(this).on('init', function(event, slick) {
                  //console.log('init');
                });

                $(this).on('setPosition', function(event, slick) {
                  //console.log('setPosition');
                  renderCounter(slick,carouselId);
                });

                $(this).on('afterChange', function(event, slick, currentSlide){
                  //console.log('afterChange');
                });

                $(this).on('edge', function(event, slick, direction){
                  //console.log('edge was hit');
                });

                $(this).slick();

              }
            });

          },
          400
        );


        $(".section-headline:contains(' // ')").html(function(_, html) {
          return  html.replace(/\/{2,}/g, ' <span class="icon-typosign"></span> ');
        });

        // replace double slashes with icon
        function replace_slashes() {




          $(".section-headline:contains(' // ')").html(function(_, html) {
            return  html.replace(/\/{2,}/g, ' <span class="icon-typosign"></span> ');
          });

          $("section .post-item-hover-text:contains(' // ')").html(function(_, html) {
            return  html.replace(/\/{2,}/g, ' <span class="icon-typosign"></span> ');
          });
          $("section .post-item-hover-name:contains(' // ')").html(function(_, html) {
            return  html.replace(/\/{2,}/g, ' <span class="icon-typosign"></span> ');
          });
        }


        // Load ajax posts
        function load_ajax_posts () {
            var settingsKey = $(this).attr('data-posts-selector-index');
            var currentPostID = $(this).attr('data-posts-selector-current-post-id');

            var currentManualTeaserArray = $(this).attr('data-posts-selector-manual-teaser-array');
            var currentCategory = $(this).attr('data-posts-selector-category');
            var currentTag = $(this).attr('data-posts-selector-tag');
            var currentHowManyTeasers = $(this).attr('data-posts-selector-how-many-teasers');
            var currentNumRowsMobile = $(this).attr('data-posts-selector-num-rows-mobile');
            var currentNumRowsDesktop = $(this).attr('data-posts-selector-num-rows-desktop');

            var currentPostCount = $(this).find('.load-more').attr('data-load-more-current-post-count');
            var currentOffset = $(this).find('.load-more').attr('data-load-more-current-offset');

            var currentPostsPerPage = -1;
            var currentMediaquery = '';

            if (currentHowManyTeasers !== 'all') {
              if (Foundation.MediaQuery.is('small only')) {
                  currentPostsPerPage = currentNumRowsDesktop;
              }
              if (Foundation.MediaQuery.is('medium only')) {
                  currentPostsPerPage = currentNumRowsDesktop * 1;
              }
              if (Foundation.MediaQuery.is('large only')) {
                  currentPostsPerPage = currentNumRowsDesktop * 1;
              }
              if (Foundation.MediaQuery.atLeast('xlarge')) {
                  currentPostsPerPage = currentNumRowsDesktop * 1;
              }
            }

            if (Foundation.MediaQuery.is('small only')) {
                currentMediaquery = 'small';
            }
            if (Foundation.MediaQuery.is('medium only')) {
                currentMediaquery = 'medium';
            }
            if (Foundation.MediaQuery.is('large only')) {
                currentMediaquery = 'large';
            }
            if (Foundation.MediaQuery.atLeast('xlarge')) {
                currentMediaquery = 'xlarge';
            }

            if (!($('.posts-selector-multiple-' + settingsKey).hasClass('loader-loading'))) {

              $.ajax({
                type: "GET",
                url: SiteParameters.ajax_url,
                dataType: 'html',
                data: ({
                  action: 'load_responsive_posts',
                  currentsettingskey: settingsKey,
                  currentpostid: currentPostID,
                  currentpostsperpage: currentPostsPerPage,
                  currentmediaquery: currentMediaquery,
                  currentmanualteaserarray: currentManualTeaserArray,
                  currentcategory: currentCategory,
                  currenttag: currentTag,
                  currentpostcount: currentPostCount,
                  currentoffset: currentOffset
                }),
                beforeSend : function () {
                  $('.posts-selector-multiple-' + settingsKey).addClass('loader-loading');
                },
                success: function(data) {
                  if(data) {
                    if ( $('#load-more-' + settingsKey).length > 0 ) {
                      $('#load-more-' + settingsKey).replaceWith(data);
                    } else {
                      $('.posts-selector-multiple-' + settingsKey).prepend(data);
                    }






                    replace_slashes();

                  } else {
                    console.log("no data");
                  }
                },
                error: function(data) {
                  console.log("Error :( " + data);
                  return false;
                },
                complete:   function() {
                  replace_slashes();

                  $('.posts-selector-multiple-' + settingsKey).removeClass('loader-loading');
                  // Trigger event after the posts are loaded
                  var loadResponsivePostsCompleteEvent = new Event('loadResponsivePostsComplete');
                  document.dispatchEvent(loadResponsivePostsCompleteEvent);
                }
              });
            }




            return false;
        }

        var resizeMorePosts = null;
        clearTimeout(resizeMorePosts);
        resizeMorePosts = setTimeout(
          function() {
            var numSlides = $('.slick-dots li').length;
            $( ".slick-dots li:last-child" ).after( '<span class="total"> / '+ numSlides +'</span>' );
            $('.slick-prev').appendTo(".slick-dots");
            $('.slick-next').appendTo(".slick-dots");

            $('.news-section .post-item-wrap').each(function(){
              $(this).remove();
            });
            $('.news-section .load-more').each(function(){
              $(this).remove();
            });
            $.each($('.posts-selector-multiple'), load_ajax_posts);
          },
          400
        );


        $(".posts-selector-multiple").on('click', 'button.load-more', function() {
          var currentParent = $(this).closest('.posts-selector-multiple');
          $.each(currentParent, load_ajax_posts);
        });

        document.addEventListener('loadResponsivePostsComplete', function(e) {
          //console.log("loadResponsivePostsComplete");
        }, false);

        // Sticky footer
        function stickyFooter() {
          if (Foundation.MediaQuery.atLeast('large')) {
            $("main.main").css('min-height', combinedHeight + "px");
          } else {
            $("main.main").css('min-height', "100vh");
          }
        }

        if ($( "#footer-wrap" ).length) {
          stickyFooter();
        }

      },
      windowload: function() {

        function slideNumber() {
          var $slides = $('.orbit-slide');
          var $activeSlide = $slides.filter('.is-active');
          var activeNum = $slides.index($activeSlide) + 1;
          $('.slide-number').html(activeNum);
          //console.log(activeNum);
        }
        $('[data-orbit]').on('slidechange.zf.orbit', slideNumber);


        //console.log('loaded');
        $('html').addClass('window-loaded');
      }
    },
    // Home page
    'home': {
      init: function() {

        jQuery(function($) {

          var $nav = $('#main-header');
          var $win = $(window);
          var winH = $win.height();

          $win.on("scroll", function () {
            if ($(this).scrollTop() > winH ) {
              $nav.addClass("teaser-header");
            } else {
              $nav.removeClass("teaser-header");
            }
          }).on("resize", function(){
            winH = $(this).height();
          });

        });
        // JavaScript to be fired on the home page


        // open popup and scroll to selected slide
        $('.portfolio-link-internal').click(function(){
              var slide_id = $(this).attr('data-indexnum');
              $("#bullet"+ slide_id).click();
              $('#portfolioModal').foundation('open');
            $('#portfolioModal').toggleClass('open');
              console.log(slide_id);
              return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      },
      resize: function() {





      },
      windowload: function() {

      }
    },
    'page_template_template_layouts': {
      init: function() {

      },
      finalize: function() {

      },
      resize: function() {

      },
      windowload: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
        //UTIL.fire(classnm, 'resize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
      //UTIL.fire('common','resize');
    },
    resizeEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'resize');
      });

      UTIL.fire('common','resize');

    },
    windowLoadEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm, 'windowload');
      });

      UTIL.fire('common','windowload');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).on("load",UTIL.windowLoadEvents);

  // Resize Events
  var trackWindowWidth = $(window).width();

  $(window).resize(function (trackResizeEvent) {
      //console.log(!trackResizeEvent.isTrigger ? ' resize' : ' triggerd');

      // Check window width has actually changed and it's not just iOS triggering a resize event on scroll || the resize event was caused by $(window).trigger('resize');
      if (($(window).width() !== trackWindowWidth) || trackResizeEvent.isTrigger) {
          // Update the window width for next time
          trackWindowWidth = $(window).width();

          UTIL.resizeEvents();
      }
  });


})(jQuery); // Fully reference jQuery after this point.

